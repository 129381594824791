import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import PageTitle from '../PageTitle'
import {Link} from "gatsby";
import TalkCards from "../ExperiencePage/TalkCards";

const Hero = () => {
  return (
    <div className="w-full h-full">
      <div className="mx-10 mb-10 mt-20 h-48 lg:h-96 relative">
        <StaticImage
          src="../../images/about-hero.jpg"
          className="w-full h-full object-cover z-20 relative"
          alt=""
        />
        <div className="absolute top-0 left-0 w-full h-full bg-white z"></div>
      </div>
      <div className="w-4/5 mx-auto">
        <div className="w-full xl:w-1/2">
          <div className="flex items-center">
            <PageTitle title="About me" />
            <span className="block ml-5 text-sm text-gray-400">(WIP)</span>
          </div>
          <p className="font-primary text-lg mt-5 leading-9 text-gray-100">
            I{"'"}m 20 y/o front-end engineer, projects leader, and
            event organizer based in Warsaw, Poland. <br />
          </p>
          <span className="block mt-10 text-gray-400 font-primary">
            This site highlights my motivations and interests, <br />
            if you are rather interested in my professional background, <br />
            please visit <i>Experience</i> page.
            <br />
          </span>
        </div>
      </div>

      <div className=" py-20 my-20">
        <div className="w-4/5 mx-auto">
          <span className="text-center text-gray-300 block font-primary mb-5">
            In my opinion
          </span>
          <h3 className="text-center text-gray-100 font-primary italic text-3xl leading-loose">
            Building for the web is an incredible adventure <br />
            in which{' '}
            <span className="text-accent font-secondary text-5xl">
              art
            </span>{' '}
            &nbsp;and tech allow us to{' '}
            <span className="underline">emphasize</span> great stories.
          </h3>
        </div>
      </div>
      <div className="mt-20 w-4/5 mx-auto">
        <div className="">
          <h1 className="text-7xl font-secondary text-gray-100 mb-20">
            <span className="text-accent">I</span>
            nterests
          </h1>
          <div className="grid xl:grid-cols-3 gap-20">
            <div className="">
              <h3 className="text-3xl font-primary font-bold text-gray-100">
                Civic tech & open source
              </h3>
              <p className="mt-5 text-gray-100 font-primary">
                I{"'"}m an open-source maintainer and civic-tech enthusiast.
                I believe that these movements serve the most crucial goal technology has,
                which is to empower people with help from the community.
                <br />
                You can check my open-source projects on GitHub.
              </p>
            </div>
            <div className="">
              <h3 className="text-3xl font-primary font-bold text-gray-100">
                UX
              </h3>
              <p className="mt-5 text-gray-100 font-primary">
                For me, one of the best things about software development is
                that you get to help experts in their fields fulfill their
                mission. Although I{"'"}m fairly new to the UX field, I seek in
                it confirmation that our help as engineers is truly useful for
                end users.
              </p>
            </div>
            <div className="">
              <h3 className="text-3xl font-primary font-bold text-gray-100">
                Communities
              </h3>
              <p className="mt-5 text-gray-100 font-primary">
                The greatest achievements are made thanks to teamwork! <br />
                <br />
                Currently I{"'"}m a member of:
                <ul className="text-gray-100  font-primary list-disc">
                  <li>Google Developer Groups Warsaw</li>
                  <li>GitHub Campus Experts</li>
                  <li>Hack Club</li>
                  <li>CyberSecurity Student Club @ WAT</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 w-4/5 xl:w-1/2 mx-auto flex justify-center">
        <div>
          <h1 className="text-7xl font-secondary text-gray-100">
            <span className="text-accent">A</span>
            wards
          </h1>
          <ul className="font-primary text-lg mt-5 leading-9 text-gray-100 list-disc">
            <li className="my-4">
              1st place in HackYeah 2019 - the biggest stationary hackathon in
              Europe
              <ul>
                <li className="text-gray-400 ml-5">
                  We created an app called ForestVille which would facilitate
                  planting trees by citizens. <br />
                  We got mentioned by Business Insider Poland.
                  {/*https://businessinsider.com.pl/wiadomosci/hackyeah-2019-polski-sukces-pisany-jest-kodem/wz2eg2s*/}
                </li>
              </ul>
            </li>
            <li className="my-4">
              Finalist of HackCrisis Hackathon (Mar 2020)
              <ul>
                <li className="text-gray-400 ml-5">
                  We created an app to self-examine symptoms of COVID-19.
                </li>
              </ul>
            </li>
            <li className="my-4">
              2nd place in CSR Knowledge Competition organized by Responsible
              Business Forum Poland (Jun 2020){' '}
              <ul>
                <li className="text-gray-400 ml-5">
                  I presented a talk about the risks of automation in the coming
                  years.
                  {/*https://businessinsider.com.pl/wiadomosci/hackyeah-2019-polski-sukces-pisany-jest-kodem/wz2eg2s*/}
                </li>
              </ul>
            </li>
            <li className="my-4">
              Finalist of Polish edition of European Union Contest for Young
              Scientists (Mar 2022){' '}
              <ul>
                <li className="text-gray-400 ml-5">
                  After gathering responses from almost half a thousand students
                  I prepared a research paper {'"'}Analysis of the information
                  needs of candidates for upper secondary schools in the Warsaw
                  metropolitan area in the context of feature planning for
                  recruitment supporting app.{'"'}
                  {/*https://businessinsider.com.pl/wiadomosci/hackyeah-2019-polski-sukces-pisany-jest-kodem/wz2eg2s*/}
                </li>
              </ul>
            </li>
            <li className="my-4">
              3rd place in the SendHybrid challenge by Polish Ministry of Finance at HackYeah 2022 - the biggest stationary hackathon in
              Europe
              <ul>
                <li className="text-gray-400 ml-5">
                  The challenge was about preprocessing digital documents so they can be printed and mailed.
                </li>
              </ul>
            </li>
            <li className="my-4">
              Featured on the TNW's T500 list (Feb 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                  T500 is a list prepared by The Next Web of the top 500 most talented people under the age of 30 in the Dutch tech space.
                </li>
              </ul>
            </li>
            <li className="my-4">
              Honorable mention for a speech at First National Student Cybernetics Symposium  (May 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                  I prepared a speech "Why participate in open-source projects? An introduction to the first contribution."
                  First National Student Symposium was hosted by Military University of Technology in Poland.
                </li>
              </ul>
            </li>
            <li className="my-4">
              Main prize "The best substantive work" in the competition for students organized as part of the Polish ICT Forum  (Sep 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                  I talked about "po8klasie" - open-sourced, civic-tech tool to aid recruitment to secondary schools.
                  Selected out of 70 submissions.
                  Polish ICT Forum is an annual event under the patronage of the Minister of Digital Affairs and the Rector of the University of Warsaw.
                </li>
              </ul>
            </li>
            <li className="my-4">
              2nd place in the "Education in Innovation" challenge by GovTechPL at HackYeah 2023 - the biggest stationary hackathon in
              Europe  (Sep 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                 Our team presented a concept of lookup service for universities. We competed with 71 teams.
                </li>
              </ul>
            </li>
            <li className="my-4">
              6th place in the "Immersed in the sounds of space" challenge in NASA Space Apps Challenge Stalowa Wola 2023 (Oct 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                  We created "AstroSonix" - an app allowing users to sonify images. To analyze the emotions accompanying visualizations, we used LLMs and computer vision heuristics.
                </li>
              </ul>
            </li>
            <li className="my-4">
              1st place in the #innovateFinance challenge in Collabothon Łódź 2023 (Oct 2023)
              <ul>
                <li className="text-gray-400 ml-5">
                  In this edition of the annual hackathon organized by CommerzBank we archived first place by developing an app intended for the youngest bank customers.
                </li>
              </ul>
            </li>
            <li className="my-4">
              3rd place in the Social Impact category in data4good hackathon at Hertie School Berlin (Apr 2024)
              <ul>
                <li className="text-gray-400 ml-5">
                  We created ecommute Berlin: a mobile app incentivizing eco-friendly transportation choices in Berlin! By facilitating carpooling and bike rentals, users earn rewards while reducing pollution. Our neural network AI model estimates emission cuts, empowering Berliners to contribute to a cleaner environment and earn perks.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-20 w-4/5 xl:w-1/2 mx-auto">
        <div className="mb-10">
          <h1 className="text-7xl font-secondary text-gray-100 text-center">
            <span className="text-accent">T</span>
            alks & interviews
          </h1>
        </div>
        <TalkCards />
      </div>
      <div className="mt-20 w-4/5  mx-auto">
        <div className="mb-10">
          <h1 className="text-7xl font-secondary text-gray-100 text-center">
            <span className="text-accent">P</span>
            ress
          </h1>
        </div>
        <div className="">
          <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-10">
            <div className="h-full">
              <StaticImage className="w-full h-60" alt="" src="../../images/press/wcy_wat_sukces_po8klasie.jpg"  />
              <div className="py-1 px-2 border-b-2 border-x-2 border-primary h-40">
                <span className="block uppercase">OGROMNY SUKCES TWÓRCÓW PO8KLASIE PODCZAS HACKYEAH 2023 (PL)</span>
                <span className="text-sm block italic py-1">Cybernetics Faculty News | 10.10.2023</span>
                <a
                    href="https://wcy.wat.edu.pl/pl/wydzial/aktualnosci/ogromny-sukces-tworcow-po8klasie-podczas-hackyeah-2023"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-accent text-sm hover:underline">Read more</a>
              </div>
            </div>
            <div className="h-full">
              <StaticImage className="w-full h-60" alt="" src="../../images/press/pw_news_astrosonix.jpg" />
              <div className="py-1 px-2 border-b-2 border-x-2 border-primary h-40">
                <span className="block uppercase">Aplikacja, która pozwala usłyszeć obrazy z kosmosu (PL)</span>
                <span className="text-sm block italic py-1">Warsaw University of Technology News | 19.10.2023</span>
                <a
                    href="https://www.pw.edu.pl/Aktualnosci/Aplikacja-ktora-pozwala-uslyszec-obrazy-z-kosmosu"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-accent text-sm hover:underline">Read more</a>
              </div>
            </div>
            <div className="h-full">
              <StaticImage className="w-full h-60" alt="" src="../../images/press/nauka_w_polsce_astrosonix.jpg" />
              <div className="py-1 px-2 border-b-2 border-x-2 border-primary h-40">
                <span className="block uppercase">Aplikacja do słuchania obrazów z kosmosu (PL)</span>
                <span className="text-sm block italic py-1">Nauka w Polsce - MEiN | 19.10.2023</span>
                <a
                    href="https://naukawpolsce.pl/aktualnosci/news%2C98971%2Caplikacja-do-sluchania-obrazow-z-kosmosu.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-accent text-sm hover:underline">Read more</a>
              </div>
            </div>
            <div className="h-full">
              <StaticImage className="w-full h-60" alt="" src="../../images/press/wcy_wat_sukces_po8klasie.jpg" />
              <div className="py-1 px-2 border-b-2 border-x-2 border-primary h-40">
                <span className="block uppercase">Wyszukiwarka studenta WAT pomaga uczniom i przyszłym studentom (PL)</span>
                <span className="text-sm block italic py-1">Military University of Technology | 20.10.2023</span>
                <a
                    href="https://promocja.wat.edu.pl/mlodziinnowatorzy/wyszukiwarka-studenta-wat-pomaga-uczniom-i-przyszlym-studentom/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-accent text-sm hover:underline">Read more</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-20 py-10">
        <div className="w-4/5 mx-auto text-center">
          <h1 className="text-4xl font-primary text-gray-100 mb-10 italic">
            Did something catch your eye?{' '}
          </h1>
          <Link to="/contact" className="inline-block text-2xl text-black bg-accent rounded font-primary uppercase tracking-wider py-2 px-6">
            Contact me
          </Link>
          <span className="mx-5 text-gray-100 block my-2 lg:my-0 lg:inline-block">
            or
          </span>

          <a
              href="https://ko-fi.com/micorix"
              target="_blank"
              rel="noreferrer noopener"
              className="inline-block text-2xl border-2 border-transparent bg-white bg-opacity-10 text-gray-100 rounded font-primary uppercase tracking-wider py-2 px-6">
            Consider supporting me
          </a>
        </div>
      </div>
    </div>
  )
}
//                <button className="text-2xl border-2 border-accent text-gray-100 rounded font-primary uppercase tracking-wider py-2 px-6">Consider supporting me</button>
export default Hero
//              {/*<div className="mt-6 w-1/2 mx-auto bg-white p-7 rounded-xl shadow-xl">*/}
//                 {/*    <h1 className="text-5xl font-secondary">Few words about me</h1>*/}
//                 {/*    <p className="font-primary mt-4 text-lg">*/}
//                 {/*        I'm 19 y/o web apps developer and also an assistant at Websites Designing Lab at the Palace of Youth in Warsaw, Poland.*/}
//                 {/*        <br />*/}
//                 {/*        Building for the web is an incredible adventure where art and tech combine to emphasize great stories. It's a thing which I've been fascinated about since I was 10.*/}
//                 {/*        <br />*/}
//                 {/*        Tech also provides us tools to improve things around us a little bit. That's why I launched few projects (you can see them below ;)*/}
//                 {/*        <br />*/}
//                 {/*        I was doing my best at HackYeah 2019 - the biggest stationary hackathon in Europe. My team and I gained first place in the environment category.*/}
//                 {/*        <br />*/}
//                 {/*        Recently with my friends and I kicked off with the HackClub.pl community. It's a community of young people interested in technology and programming.*/}
//                 {/*        <br />*/}
//                 {/*        I also want to start my blogging adventure but other responsibilities are holding me back. I promise it will be live soon ;)*/}
//                 {/*        <br />*/}
//                 {/*        Besides I like to roam with friends around the city, then go home, listen to Indie folk or play the guitar a bit, and... skip lessons the next day (just kidding ;).*/}
//                 {/*    </p>*/}
//                 {/*</div>*/}
//
//         {/*    </div>*/}
//         {/*    <div className="">*/}
//
//         {/*    </div>*/}
//         {/*    /!*<div className="h-96 w-full bg-black"></div>*!/*/}
//         {/*    /!*<h1 className="text-3xl font-secondary">Why web development?</h1>*!/*/}
//         {/*    /!*<h1 className="text-3xl font-secondary">Communities</h1>*!/*/}
//         {/*    /!*<h1 className="text-3xl font-secondary">Interests</h1>*!/*/}
//         {/*    /!*<h1 className="text-3xl font-secondary">Awards</h1>*!/*/}
//         {/*    /!*<h1 className="text-3xl font-secondary">Resume</h1>*!/*/}
//         {/*</div>*/}
//         {/*</div>*/}
