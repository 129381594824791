import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/AboutPage/Hero'
import SEO from "../components/SEO";

const AboutPage = () => (
  <>
    <Navbar />
    <Hero />
  </>
)

export const Head = () => (
    <SEO title="About me" pathname="/about" />
)

export default AboutPage
