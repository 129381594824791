import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
interface TalkItemProps {
  name: string
  description: string
  date: string
  url: string
}

const TalkItem: FC<TalkItemProps> = ({
  name,
  description,
  date,
  url
}) => {
  return (
      <div className="flex justify-center">
      <div>
        <div>
          <span className="text-center block mb-2">{date}</span>
          <h4 className="font-bold text-xl text-center">{name}</h4>
        </div>
        <p className="mt-1 text-center">{description}</p>
        <a href={url as string} className="text-accent text-center block mt-4">Learn more</a>
      </div>
  </div>
  )
}

const TalkCards = () => {
  const {
    experience: {talksAndInterviews},
  } = useStaticQuery<Queries.TalksAndInterviewsCardsQuery>(graphql`
    query TalksAndInterviewsCards {
      experience {
        talksAndInterviews {
          description
          name
          date
          url
        }
      }
    }
  `)

  return (
    <div className="">
      {talksAndInterviews.map(({ name, description, date, url}) => {
        return (
          <div className="my-10">
            <TalkItem
                name={name}
                description={description}
                date={date}
                url={url}
            />
          </div>
        )
      })}
    </div>
  )
}

export default TalkCards
